const mocks = {
  auth: { 
    POST: [

      // аккаунты 

      { 
        token: "This-is-a-mocked-token",
        username: '9055',
        user_pass: 'lkgjsof',
        status: '20',
      },
      { 
        token: "This-is-a-mocked-token",
        username: 'manufacturing_man',
        user_pass: 'gh7kd23',
        status: '23',
      },
      { 
        token: "This-is-a-mocked-token",
        username: 'admin_man',
        user_pass: 'dasdg214',
        status: '966',
      },

// дизайнеры

      { 
        token: "This-is-a-mocked-token",
        username: 'Azura',
        user_pass: 'Daedr1c',
        status: '1660543483156 , 1611298923259',
      },
      { 
        token: "This-is-a-mocked-token",
        username: 'Farida_Siraeva',
        user_pass: 'Kj01JdaQ',
        status: '1653653337197',
      },
      { 
        token: "This-is-a-mocked-token",
        username: 'faina',
        user_pass: 'j14heArt',
        status: '1644848510234',
      },
      { 
        token: "This-is-a-mocked-token",
        username: 'dima',
        user_pass: '1947saf',
        status: '1625815081116',
      },
      { 
        token: "This-is-a-mocked-token",
        username: 'Chivieva_Alexandra',
        user_pass: 'fdgh452',
        status: '1613570822840 , 1740084662255',
      },
      { 
        token: "This-is-a-mocked-token",
        username: 'Наталья Колмакова',
        user_pass: 'BV88Ja',
        status: '1706799430646',
      },
      { 
        token: "This-is-a-mocked-token",
        username: 'sophiHH',
        user_pass: 'sfd14145',
        status: '1611298923259 , 1660543483156',
      },
      { 
        token: "This-is-a-mocked-token",
        username: 'Maxim',
        user_pass: 'KJ18aPk',
        status: '1693906709344',
      },
      { 
        token: "This-is-a-mocked-token",
        username: 'Olesya',
        user_pass: 'Ja092Lfk',
        status: '1644838621083',
      },
      { 
        token: "This-is-a-mocked-token",
        username: 'Полина Лепкова',
        user_pass: 'kdA1eq2',
        status: '1622560085305',
      },
      { 
        token: "This-is-a-mocked-token",
        username: 'Виктория Санторская',
        user_pass: 'Lop89Ja',
        status: '1697613289809',
      },
      { 
        token: "This-is-a-mocked-token",
        username: 'Алёна Зелинская',
        user_pass: 'Uuh954A',
        status: '1726060810750',
      },
      { 
        token: "This-is-a-mocked-token",
        username: 'Софья Абишева',
        user_pass: 'm4ur96',
        status: '1738776488064 , 1721201198827',
      },
      { 
        token: "This-is-a-mocked-token",
        username: 'Мария Скворцова',
        user_pass: '85Q4k7',
        status: '1730468769784',
      },
      { 
        token: "This-is-a-mocked-token",
        username: 'Елена Кудрявцева',
        user_pass: 'HAABt1',
        status: '1727108996550 , 1622560085305',
      },
      { 
        token: "This-is-a-mocked-token",
        username: 'Полина Лепкова',
        user_pass: 'nQ1fph',
        status: '1622560085305 , 1727108996550',
      },
      { 
        token: "This-is-a-mocked-token",
        username: 'Гюнель',
        user_pass: 'jf65RT',
        status: '1694883137051',
      },
    
      { 
        token: "This-is-a-mocked-token",
        username: 'Анна Заднепровская',
        user_pass: 'ksA89Pj',
        status: '1706861422357 , 1706861416463',
      },
      { 
        token: "This-is-a-mocked-token",
        username: 'Лолита',
        user_pass: 'Unz44Ya',
        status: '1706861416463 , 1706861422357',
      },
      { 
        token: "This-is-a-mocked-token",
        username: 'Дорошенко Екатерина',
        user_pass: 'AAm667',
        status: '1709295384179',
      },
      { 
        token: "This-is-a-mocked-token",
        username: 'Александр Катаев',
        user_pass: '554YtN',
        status: '1713279326086',
      },
      { 
        token: "This-is-a-mocked-token",
        username: 'Иванченко Екатерина',
        user_pass: 'kd99Jui',
        status: '1713279358769',
      },
      { 
        token: "This-is-a-mocked-token",
        username: 'Александра Грызова',
        user_pass: '19KyRRf',
        status: '1715618977060 , 1720862536119',
      },
      { 
        token: "This-is-a-mocked-token",
        username: 'Бартенева Анна',
        user_pass: 'ka990Kj',
        status: '1716903108552',
      },
      { 
        token: "This-is-a-mocked-token",
        username: 'Водолазская Юлия',
        user_pass: 'pD91Ja',
        status: '1717074524720',
      },
      { 
        token: "This-is-a-mocked-token",
        username: 'Даша Холодкова',
        user_pass: 'ks017AD',
        status: '1694883142326',
      },
      { 
        token: "This-is-a-mocked-token",
        username: 'Александра Павлова',
        user_pass: 'uy7326Qer',
        status: '1720862536119 , 1715618977060',
      },
      { 
        token: "This-is-a-mocked-token",
        username: 'Ирина Крылова',
        user_pass: 'amO8s11',
        status: '1721201198827 , 1738776488064',
      },
      { 
        token: "This-is-a-mocked-token",
        username: 'Колесова Анна',
        user_pass: 'NwA246a',
        status: '1721218523154',
      }, 
      { 
        token: "This-is-a-mocked-token",
        username: 'Оксана Пономарёва',
        user_pass: 'mLn6Gd',
        status: '1737626956732 , 1737393880879',
      }, 
      { 
        token: "This-is-a-mocked-token",
        username: 'Жанна Демидова',
        user_pass: 'ab3kKV',
        status: '1737483939498',
      }, 
      { 
        token: "This-is-a-mocked-token",
        username: 'Анастасия Гаврилова',
        user_pass: 'VmJHr7',
        status: '1737393880879 , 1737626956732',
      }, 
      { 
        token: "This-is-a-mocked-token",
        username: 'Даниил Кудряшов',
        user_pass: '4tFrsO',
        status: '1738519113492 , 1730468769784',
      }, 
      { 
        token: "This-is-a-mocked-token",
        username: 'Анастасия Ткачёва',
        user_pass: 'HJnsM3',
        status: '1739984217360 , 1740903583608',
      }, 
      { 
        token: "This-is-a-mocked-token",
        username: 'Лидия Федорова',
        user_pass: 'NqFZP1',
        status: '1740084662255 , 1613570822840',
      }, 
    
      { 
        token: "This-is-a-mocked-token",
        username: 'Алина Калашникова',
        user_pass: '5xrRiF',
        status: '1741845948371',
      },
      { 
        token: "This-is-a-mocked-token",
        username: 'Мария Абрамова',
        user_pass: 'V1PCNd',
        status: '1740903583608 , 1739984217360',
      }, 
      { 
        token: "This-is-a-mocked-token",
        username: 'Вероника Лещенко',
        user_pass: 'i9KC3A',
        status: '1740762935472 , 1741845954723',
      }, 
      { 
        token: "This-is-a-mocked-token",
        username: 'Виктория Дадина',
        user_pass: 'N92PGv',
        status: '1741845954723 , 1740762935472',
      }, 
      
      
   
     
    
    ] 
  },
  "user/me": { GET: { 
      username: 'test',
      user_pass: 'test',
    },
  }
};
  
const apiCall = ({ url, data = null, method = 'GET'}) =>
  new Promise((resolve, reject) => {

    setTimeout(() => {
      try {
        if (method == 'GET') {
          resolve(mocks[url][method]);
        }
        for (let i = 0, l = mocks[url][method].length; i < l; i++) {
          if (mocks[url][method][i].username == data.username && mocks[url][method][i].user_pass == data.password
          ) {
            resolve(mocks[url][method][i]);
          } 
        }
      } catch (err) {
        reject(new Error(err));
      }
    }, 1000);
  });
  
  export default apiCall;